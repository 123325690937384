import { ArrowDownIcon } from 'icons'
import {
    Component,
    createSignal,
    For,
    onCleanup,
    onMount,
    Show,
} from 'solid-js'

import './style/dropdown.scss'

type itemType = {
    name: string
    value: string
}
interface dropdownProps {
    class?: string
    onchange: (value: string) => void
    active: string
    items: itemType[]

    blur?: boolean
}
const Dropdown: Component<dropdownProps> = P => {
    const [active, setActive] = createSignal(false)

    let ref: HTMLElement

    const closeOnOutsideClick = (e: MouseEvent) => {
        if (ref && !ref.contains(e.target as Node)) {
            setActive(false)
        }
    }

    onMount(() => {
        if (P.blur) {
            ref.addEventListener('blur', close)
            onCleanup(() => {
                ref.removeEventListener('blur', close)
            })
        }

        document.addEventListener('click', closeOnOutsideClick)

        onCleanup(() => {
            document.removeEventListener('click', closeOnOutsideClick)
        })
    })

    function close() {
        setActive(false)
    }

    return (
        <div
            class={`dropdown ${P.class || ''}`}
            classList={{ active: active() }}
            ref={e => (ref = e)}
        >
            <button
                class='active-wrapper title_smaller'
                onclick={() => setActive(s => !s)}
            >
                <span>{P.active}</span>
                <ArrowDownIcon />
            </button>
            <div class='items-wrapper'>
                <Show when={P.items}>
                    <For each={P.items}>
                        {item => (
                            <button
                                class='item title_smaller'
                                onclick={() => {
                                    P.onchange(item.value)
                                    setActive(false)
                                }}
                            >
                                {item.name}
                            </button>
                        )}
                    </For>
                </Show>
            </div>
        </div>
    )
}

export default Dropdown
