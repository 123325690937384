import {
    EateryCategory,
    EateryFilter,
    EateryModel,
    EaterySearch,
    EaterySort,
    EateryTheme,
    FilterNumber,
    SortOrder,
} from '!/models'
import { createEffect, on } from 'solid-js'
import { createStore, unwrap } from 'solid-js/store'

type searchModel = {
    show: boolean

    query: string

    eateries: EaterySearch[]

    sort: EaterySort
    sortOrder: SortOrder

    loading: boolean

    filters: EateryFilter[]

    activeCat: number
    activeTheme: number

    advanced: boolean

    stars: FilterNumber

    is_closed: boolean
}
let default_state: searchModel = {
    show: false,
    loading: true,

    eateries: [],

    query: '',
    filters: [],

    sort: null,
    sortOrder: null,

    activeCat: -1,
    activeTheme: -1,

    advanced: false,

    stars: {
        opt: null,
        val: -1,
    },
    is_closed: null,
}

const [search, setSearch] = createStore<searchModel>({
    ...unwrap(default_state),
})

createEffect(
    on(
        () => search.show,
        show => {
            if (!show) {
                setSearch({
                    ...unwrap(default_state),
                })
            }
        }
    )
)

export { type searchModel, search, setSearch }
