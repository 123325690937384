import { LoadingElem, NotFound } from '!/comps'
import { CommentIcon, StarIcon, StatusIcon, TrashIcon } from '!/icons/account'
import { GoBackIcon } from '!/icons/login'
import { GeneModel, ReviewModel, ReviewStatePersian } from '!/models'
import { httpx } from '!/shared'
import { self } from '!/store'
import { Component, For, onMount, Show } from 'solid-js'
import { createStore, produce } from 'solid-js/store'
import { AccountChildProps } from '.'

import './style/reviews.scss'

const Reviews: Component<AccountChildProps> = P => {
    type stateType = {
        reviews: ReviewModel[]
        loading: boolean
    }

    const [state, setState] = createStore<stateType>({
        reviews: [],
        loading: true,
    })

    onMount(() => {
        if (!self.user.review?.first) return setState({ loading: false })

        get_reviews(self.user.review.first)
    })

    function get_reviews(gene: GeneModel) {
        httpx({
            method: 'GET',
            url: `/api/reviews/${gene}/`,
            onLoad(x) {
                setState({ loading: false })

                if (x.status !== 200) return

                setState(
                    produce(s => {
                        s.reviews = [...s.reviews, ...x.response.list]
                    })
                )

                if (x.response.next) {
                    get_reviews(x.response.next)
                }
            },
        })
    }

    return (
        <section class='reviews-container'>
            <button
                class='go-back icon'
                onclick={() => {
                    P.setActiveSection('')
                }}
            >
                <GoBackIcon />
            </button>
            <div class='reviews-head title_hero'>
                <CommentIcon />
                نظرات من
                <CommentIcon />
            </div>
            <div class='reviews-wrapper'>
                <Show
                    when={!state.loading}
                    fallback={
                        <>
                            <LoadingElem /> <LoadingElem /> <LoadingElem />
                        </>
                    }
                >
                    <Show
                        when={state.reviews.length >= 1}
                        fallback={<NoReviews />}
                    >
                        <For each={state.reviews}>
                            {review => <Review {...review} />}
                        </For>
                    </Show>
                </Show>
            </div>
        </section>
    )
}

const NoReviews: Component = () => {
    return (
        <div class='no-reviews-container'>
            <NotFound show='small' />
            <p class='title_small'>شما نظر ثبت شده ای ندارید!</p>
        </div>
    )
}

const Review: Component<ReviewModel> = P => {
    return (
        <div class='review'>
            <div class='review-head'>
                <div class='review-user'>
                    <div class='user-avatar'>
                        <img
                            src={
                                self.user.photo ||
                                '/public/imgs/account/avatar.webp'
                            }
                            loading='lazy'
                            decoding='async'
                        />
                    </div>
                    <div class='user-info'>
                        <div class='user-name title_small'>
                            {self.user.name}
                        </div>

                        <div class='user-data'>
                            <div class='user-stars title_smaller number'>
                                {P.star}
                                <div class='icon'>
                                    <StarIcon fill />
                                </div>
                            </div>
                            •
                            <div class='review-date title_smaller number'>
                                {new Date(P.timestamp * 1e3).toLocaleDateString(
                                    'fa-IR'
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div class='del-review'>
                    <TrashIcon />
                </div>
            </div>

            <div class='review-wrapper description'>{P.summary}</div>

            <div class='review-stats'>
                <div class='review-state '>
                    <div class='holder description'>
                        <StatusIcon />
                        آخرین وضعیت
                    </div>
                    <div
                        class='data description'
                        classList={{
                            check: P.state.includes('verified'),
                            uncheck:
                                P.state.includes('deleted') ||
                                P.state.includes('censored'),
                        }}
                    >
                        {ReviewStatePersian[P.state].icon &&
                            ReviewStatePersian[P.state].icon()}
                        {ReviewStatePersian[P.state].label}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reviews
