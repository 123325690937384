import {
    CalenderIcon,
    CodeIcon,
    IpAddressIcon,
    LinkIcon,
    QuestionIcon,
    TrashIcon,
} from '!/icons/account'
import {
    Component,
    createEffect,
    createMemo,
    createSignal,
    For,
    JSX,
    Show,
} from 'solid-js'

import { GoBackIcon, PhoneIcon } from '!/icons/login'
import { PersonFillSvg } from '!/icons/navbar'
import { createStore, produce } from 'solid-js/store'
import { AccountChildProps } from '.'

import { addAlert, LoadingElem } from '!/comps'
import { GeneModel, SessionModel, UserPhotoModel } from '!/models'
import { httpx, record_url } from '!/shared'
import { logout_user, self, setSelf } from '!/store'

const IMAGE_MIMETYPE = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/gif',
    'image/webm',
]

const BROWSERS = [
    'unknown',
    'chrome',
    'firefox',
    'safari',
    'opera',
    'edge',
] as const
type BROWSERS_TYPES = (typeof BROWSERS)[number]

const BROWSER_IMG: { [key in BROWSERS_TYPES]: string | (() => JSX.Element) } = {
    chrome: '/public/imgs/browsers/chrome.webp',
    edge: '/public/imgs/browsers/edge.webp',
    firefox: '/public/imgs/browsers/firefox.webp',
    opera: '/public/imgs/browsers/opera.webp',
    safari: '/public/imgs/browsers/safari.webp',
    unknown: () => <QuestionIcon />,
}
type BROWSER_PERSIAN = {
    [k in BROWSERS_TYPES]: string
}
const BROWSER_PERSIAN: BROWSER_PERSIAN = {
    unknown: 'مروگر',
    chrome: 'کروم',
    edge: 'edge',
    opera: 'اوپرا',
    safari: 'سافاری',
    firefox: 'فایرفاکس',
}

const DEVICES = ['unknown', 'desktop', 'mobile', 'embeded']
type DEVICES_TYPES = (typeof DEVICES)[number]

const DEVICES_IMG: { [key in DEVICES_TYPES]: string | (() => JSX.Element) } = {
    desktop: '/public/imgs/devices/labtop.webp',
    mobile: '/public/imgs/devices/iphone.webp',
    embeded: () => <CodeIcon />,
    unknown: () => <QuestionIcon />,
}
type DEVICE_PERSIAN = {
    [k in DEVICES_TYPES]: string
}
const DEVICE_PERSIAN: DEVICE_PERSIAN = {
    unknown: 'دستگاه',
    desktop: 'PC',
    mobile: 'موبایل',
    embeded: 'embeded',
}

interface dataType {
    name: string
    phone: string | null
    image: GeneModel | null

    loadingImg: boolean
}
const Info: Component<AccountChildProps> = P => {
    const [data, setData] = createStore<dataType>({
        name: self.user.name,
        phone: self.user.phone,
        image: self.user.photo,

        loadingImg: false,
    })

    createEffect(() => {
        setData({
            name: self.user.name,
            phone: self.user.phone,
            image: self.user.photo,
        })
    })

    function update_info() {
        httpx({
            url: '/api/user/',
            method: 'PATCH',
            json: {
                name: data.name,
            },
            onLoad(x) {
                if (x.status != 200)
                    return addAlert({
                        type: 'error',
                        content: 'اسم آپدیت نشد لطفا دوباره تلاش کنید!',
                        subject: 'حطا!',
                        timeout: 3,
                    })
                addAlert({
                    type: 'success',
                    content: 'اسم با موفقیت آپدیت شد!',
                    subject: 'موفق!',
                    timeout: 300,
                })

                setSelf({ fetch: true })
            },
        })
    }

    function update_photo(file: File) {
        setData({ loadingImg: true })

        let data = new FormData()
        data.set('photo', file)

        httpx({
            url: '/api/user/photo/',
            method: 'PUT',
            data,
            onLoad(x) {
                setData({ loadingImg: false })

                if (x.status != 200)
                    return addAlert({
                        type: 'error',
                        content: 'عکس آپدیت نشد لطفا دوباره تلاش کنید!',
                        subject: 'حطا!',
                        timeout: 3,
                    })

                let result = x.response as UserPhotoModel

                setSelf(
                    produce(s => {
                        s.user.photo = result.url
                    })
                )
            },
        })
    }
    function delete_photo() {
        setSelf(
            produce(s => {
                s.user.photo = null
            })
        )

        httpx({
            url: '/api/user/photo/',
            method: 'DELETE',
            onLoad(x) {
                if (x.status != 200)
                    return addAlert({
                        type: 'error',
                        content: 'عکس آپدیت نشد لطفا دوباره تلاش کنید!',
                        subject: 'حطا!',
                        timeout: 3,
                    })
            },
        })
    }

    const getImage = createMemo((): string => {
        if (!data.image) return '/public/imgs/account/avatar.webp'

        record_url(data.image)
    })

    return (
        <section class='info-container'>
            <button
                class='go-back icon'
                onclick={async () => {
                    P.setActiveSection('')

                    // setSelf(
                    //     produce(s => {
                    //         s.name = data.name
                    //         s.photo = data.image
                    //     })
                    // )
                }}
            >
                <GoBackIcon />
            </button>

            <Show when={!data.loadingImg} fallback={<LoadingElem />}>
                <label for='profile-inp' class='profile-pic-wrapper'>
                    <Show when={data.image}>
                        <div
                            class='delete-img'
                            onclick={e => {
                                e.stopImmediatePropagation()
                                e.stopPropagation()
                                e.preventDefault()

                                delete_photo()
                            }}
                        >
                            <TrashIcon />
                        </div>
                    </Show>
                    <div class='pic-holder'>
                        <LoadingElem />

                        <img
                            src={getImage()}
                            loading='eager'
                            decoding='async'
                        />
                    </div>
                    <input
                        type='file'
                        id='profile-inp'
                        accept='.jpg, .jpeg, .png, image/jpg, image/jpeg, image/png'
                        onchange={e => {
                            if (!e.target.files || !e.target.files[0]) return

                            const file = e.target.files[0]

                            if (!IMAGE_MIMETYPE.includes(file.type)) return

                            const url = URL.createObjectURL(file)

                            update_photo(file)
                        }}
                    />
                </label>
            </Show>

            <div class='info-inputs'>
                <div class='info-input'>
                    <h3 class='input-holder title_smaller'>
                        <PersonFillSvg />
                        نام کاربری
                    </h3>
                    <div class='input-wrapper '>
                        <input
                            type='text'
                            class='title_small'
                            value={data.name}
                            oninput={e => setData({ name: e.target.value })}
                            onchange={update_info}
                        />
                        <div class='input-error'></div>
                    </div>
                </div>
                <div class='info-input'>
                    <h3 class='input-holder title_smaller'>
                        <PhoneIcon />
                        شماره موبایل
                    </h3>
                    <div class='input-wrapper '>
                        <input
                            type='text'
                            class='title phone'
                            value={data.phone}
                        />
                        <div class='input-error'></div>
                    </div>
                </div>
                <MySessions />
            </div>
        </section>
    )
}

const MySessions = () => {
    const getSessions = createMemo(() =>
        self.user.sessions.filter(s => s.ip !== '0.0.0.0')
    )

    return (
        <div class='info-input sessions'>
            <div class='sessions-holder'>
                <h3 class='input-holder title_small'>
                    <LinkIcon size={23} />
                    دستگاه های من
                </h3>
                <div class='input-wrapper ' role='button'>
                    {getSessions().length}
                </div>
            </div>
            <div class='sessions-data'>
                <For each={getSessions()}>
                    {(session, id) => <Session {...session} id={id()} />}
                </For>
            </div>
        </div>
    )
}

const Session: Component<SessionModel & { id: number }> = P => {
    const [loading, setLoading] = createSignal(false)

    const browserKey = BROWSERS[P.info.browser] as BROWSERS_TYPES
    const deviceKey = DEVICES[P.info.device] as DEVICES_TYPES

    function delete_session() {
        setLoading(true)

        httpx({
            method: 'DELETE',
            url: `/api/user/sessions/${P.id}/`,
            onLoad(x) {
                setLoading(false)

                if (x.status !== 200)
                    return addAlert({
                        type: 'error',
                        content: 'دستگاه ها آپدیت نشد لطفا دوباره تلاش کنید!',
                        subject: 'حطا!',
                        timeout: 3,
                    })

                if (P.id === self.user.session_index) {
                    logout_user().then(() => {
                        location.reload()
                    })
                } else {
                    setSelf({ fetch: true })
                }
            },
        })
    }

    return (
        <div class={`session-data ${browserKey}`} role='button'>
            <Show when={loading()}>
                <LoadingElem />
            </Show>
            <div class='session-imgs'>
                <div
                    class='session-data-image description'
                    classList={{
                        svg: typeof BROWSER_IMG[browserKey] !== 'string',
                    }}
                >
                    <div class='image-wrapper'>
                        {typeof BROWSER_IMG[browserKey] === 'string' ? (
                            <img
                                src={BROWSER_IMG[browserKey] as string}
                                loading='lazy'
                                decoding='async'
                                alt=''
                            />
                        ) : typeof BROWSER_IMG[browserKey] === 'function' ? (
                            (BROWSER_IMG[browserKey] as () => JSX.Element)()
                        ) : (
                            <QuestionIcon />
                        )}
                    </div>
                    <span>{BROWSER_PERSIAN[browserKey]}</span>
                </div>
                <div
                    class='session-data-image description'
                    classList={{
                        svg: typeof DEVICES_IMG[deviceKey] !== 'string',
                    }}
                >
                    <div class='image-wrapper'>
                        {typeof DEVICES_IMG[deviceKey] === 'string' ? (
                            <img
                                src={DEVICES_IMG[deviceKey] as string}
                                loading='lazy'
                                decoding='async'
                                alt=''
                            />
                        ) : typeof DEVICES_IMG[deviceKey] === 'function' ? (
                            (DEVICES_IMG[deviceKey] as () => JSX.Element)()
                        ) : (
                            <QuestionIcon />
                        )}
                    </div>
                    <span>{DEVICE_PERSIAN[deviceKey]}</span>
                </div>
            </div>

            <div class='session-data-info '>
                <div class='info-row title_smaller'>
                    <div class='info-holder'>
                        <IpAddressIcon size={20} />
                        ip
                    </div>
                    <div class='info-data'>{P.ip}</div>
                </div>
                <div class='info-row title_smaller'>
                    <div class='info-holder'>
                        <CalenderIcon size={20} />
                        تاریخ
                    </div>
                    <div class='info-data'>
                        {new Date(P.timestamp * 1e3).toLocaleDateString(
                            'fa-IR'
                        )}
                    </div>
                </div>
            </div>
            <button
                class='del-session title_small'
                onclick={() => {
                    //  make sure fisrt
                    // then
                    delete_session()
                }}
            >
                <TrashIcon />
                حذف
            </button>
        </div>
    )
}

export default Info
