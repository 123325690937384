export const XIcon = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        stroke-width='1.5'
        stroke='currentColor'
    >
        <path
            stroke-linecap='round'
            stroke-linejoin='round'
            d='M6 18L18 6M6 6l12 12'
        />
    </svg>
)

export const CircleXIcon = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
    >
        <circle cx='12' cy='12' r='10' />
        <path d='m15 9-6 6' />
        <path d='m9 9 6 6' />
    </svg>
)

export const CircleCheckIcon = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
    >
        <circle cx='12' cy='12' r='10' />
        <path d='m9 12 2 2 4-4' />
    </svg>
)

export const CheckIcon = () => (
    <svg
        stroke='currentColor'
        fill='currentColor'
        stroke-width='0'
        version='1'
        viewBox='0 0 48 48'
        enable-background='new 0 0 48 48'
        height={25}
        width={25}
        xmlns='http://www.w3.org/2000/svg'
    >
        <polygon
            fill='#43A047'
            points='40.6,12.1 17,35.7 7.4,26.1 4.6,29 17,41.3 43.4,14.9'
        ></polygon>
    </svg>
)

export const CircleAlertIcon = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        stroke-width='2'
        stroke-linecap='round'
        stroke-linejoin='round'
    >
        <circle cx='12' cy='12' r='10' />
        <path d='M12 16v-4' />
        <path d='M12 8h.01' />
    </svg>
)
