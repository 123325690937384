import { Component, createEffect, createSignal, onCleanup } from 'solid-js'

import './style/notfound.scss'

let IMGS = {
    small: '/public/imgs/gooje/gooje-404-small.webp',
    big: '/public/imgs/gooje/gooje-404-big.webp',
}

interface NotFoundProps {
    title?: string
}

const MIN_WIDTH = 100

interface NotFoundProps {
    show?: 'small' | 'big'
}

export const NotFound: Component<NotFoundProps> = P => {
    const [parentSize, setSize] = createSignal<number>(0)

    let ref: HTMLElement

    createEffect(() => {
        if (!ref || P.show) return

        let parent = ref.parentNode as HTMLElement

        let p_width = parent?.clientWidth || 0

        const updateSize = () => {
            setSize(p_width)
        }

        // Initial size check
        updateSize()

        window.addEventListener('resize', updateSize)

        onCleanup(() => {
            window.removeEventListener('resize', updateSize)
        })
    })

    if (!P.show) {
        P.show = parentSize() < MIN_WIDTH ? 'small' : 'big'
    }

    return (
        <div class={`not-found-comp ${P.show || ''}`} ref={e => (ref = e)}>
            <img src={IMGS[P.show]} loading='lazy' decoding='async' />
        </div>
    )
}
