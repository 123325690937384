import { SaveIcon } from '!/icons'
import { GoBackIcon } from '!/icons/login'
import { Component } from 'solid-js'
import { createStore } from 'solid-js/store'
import { AccountChildProps } from '.'

type storeType = {
    saves: string[]
}

const Saved: Component<AccountChildProps> = P => {
    const [store, setStore] = createStore<storeType>({
        saves: [],
    })

    // onMount(async () => {
    //     let resultDB: SaveModel[] = await getDB(USER_SAVE)
    //     if (!resultDB) return

    //     let resultCopy: SaveCopyModel[] = []

    //     resultDB.map(s => {
    //         resultCopy = [...resultCopy, { ...s, save: true }]
    //     })

    //     setStore({ saves: resultCopy })
    // })

    return (
        <section class='saved-container'>
            <button
                class='go-back icon'
                onclick={async () => {
                    // let save: SaveModel[] = store.saves.map(
                    //     ({ save, ...s }) => {
                    //         if (save) return s
                    //     }
                    // )

                    // await setDB(
                    //     USER_SAVE,
                    //     save.filter(value => value !== undefined)
                    // )

                    P.setActiveSection('')
                }}
            >
                <GoBackIcon />
            </button>

            <header class='title_hero'>
                <SaveIcon fill />
                مورد علاقه هام
                <SaveIcon fill />
            </header>
            <div class='saved-wrapper'>
                {/* {store.saves.length >= 1 ? (
                    store.saves.map((s, index) => {
                        return (
                            <SaveRow
                                data={s}
                                onclick={() => {
                                    setStore(
                                        produce(state => {
                                            state.saves[index].save =
                                                !state.saves[index].save
                                        })
                                    )
                                }}
                            />
                        )
                    })
                ) : (
                    <></>
                )} */}
            </div>
        </section>
    )
}

// interface SaveRowProps {
//     onclick: () => void
//     data: SaveCopyModel
// }

// const SaveRow: Component<SaveRowProps> = P => {
//     return (
//         <div class='save-row' role='button' onclick={P.onclick}>
//             <div class='save-action' classList={{ active: P.data.save }}>
//                 <SaveFillIcon size={25} />
//                 <SaveIcon size={25} />
//             </div>
//             <img
//                 class='row-img'
//                 src={P.data.image}
//                 loading='lazy'
//                 decoding='async'
//                 alt=''
//             />
//             <div class='row-data'>
//                 <div class='stars'>
//                     {Array.from(Array(5).keys()).map((_, index) => {
//                         let fill = index + 1 <= P.data.stars
//                         return (
//                             <StarIcon
//                                 fill={fill}
//                                 className={fill ? 'fill' : ''}
//                             />
//                         )
//                     })}
//                 </div>
//                 <h4 class='title_small save-name'>اسم رستوران</h4>
//                 <h5 class='save-date title_small'>09 / 09 / 1400</h5>
//             </div>
//         </div>
//     )
// }

export default Saved
