import './preload'

import { Alert } from 'comps'
import Navbar from 'layout/navbar'
import { lazy, onCleanup, onMount, Show } from 'solid-js'
import { render } from 'solid-js/web'
import { activeTab, self } from './store'

const Home = lazy(() => import('!/pages/home'))
const Map = lazy(() => import('!/pages/map'))
const Account = lazy(() => import('!/pages/account'))
const Login = lazy(() => import('!/pages/login'))
const AddHome = lazy(() => import('!/comps/addHome'))

import './style/base.scss'
import './style/config.scss'
import './style/pcmode.scss'
import './style/theme.scss'

const APP_ID = 'gooje-offical-pwa'

export type MapChilrenType = {
    active: boolean
}

export const App = () => {
    onMount(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/public/sw.js')
        }

        document.addEventListener(
            'contextmenu',
            function (e) {
                e.preventDefault()
            },
            false
        )

        onCleanup(() => {
            document.removeEventListener(
                'contextmenu',
                function (e) {
                    e.preventDefault()
                },
                false
            )
        })
    })

    let isInWebAppiOS =
        /* @ts-ignore */
        'standalone' in window.navigator && window.navigator.standalone === true

    let isInWebAppChrome = window.matchMedia(
        '(display-mode: standalone)'
    ).matches

    let isOnSafari =
        /Safari/.test(navigator.userAgent) &&
        !/Chrome/.test(navigator.userAgent)
    let isOnChrome = /Chrome/.test(navigator.userAgent)

    /* @ts-ignore */
    let isIos = /iPhone|iPad|iPod/.test(navigator.userAgent) && !window.MSStream
    let isAndroid = /Android/.test(navigator.userAgent)

    /* Determine if the app is installed */
    let showAddHomeBanner =
        (isIos && isOnSafari && !isInWebAppiOS) ||
        (isAndroid && isOnChrome && !isInWebAppChrome)

    return (
        <>
            <Show when={self.user?.gene} fallback={<Login />}>
                {/* @ts-ignore */}
                <Show when={showAddHomeBanner}>
                    <AddHome />
                </Show>

                <div class='main-containers'>
                    <Map active={activeTab() === 'map'} />
                    <Account active={activeTab() === 'account'} />
                </div>

                <Navbar />
            </Show>
            <Alert />
        </>
    )
}

render(() => <App />, document.getElementById('root'))
