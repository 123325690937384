import { JSX } from 'solid-js'
import {
    BurgerRef,
    Check2Icon,
    CheckIcon,
    ChopstickRef,
    CloseIcon,
    CoffeeRef,
    EyeIcon,
    IcecreamRef,
    PizzaRef,
    QuestionIcon,
    RestaurantRef,
    TrashIcon,
} from './icons'

export type GeneModel = string

export type PondInfo = {
    first: string
    gene: string
    is_alive: boolean
    is_edited: boolean
    is_private: boolean
    items: number
    last: string
    owner: string
    ponds: number
}

export type SelfModel = {
    token: string
    user: UserInfo
    agent: AgentModel
    session: SessionModel
}

export type AgentModel = {
    admin_perms: string
    gene: GeneModel
    is_alive: boolean
    is_edited: boolean
    is_private: boolean
}

// export type UserModel = {
//     gene: GeneModel
//     flag: number
//     review: GeneModel | null
//     photo: GeneModel | null
//     reviews: number[]
//     phone: string
//     cc: number
//     name: string
//     sessions: SessionModel[]
// }

export type UserInfo = Omit<UserModel, 'flag' | 'photo'> & {
    photo: UserPhotoModel | null
}

export type UserPhotoModel = {
    checksum: string
    duration: number
    gene: string
    height: number
    is_alive: false
    is_edited: false
    is_private: false
    issue: string
    mime: 'unknown' | 'webp' | 'png' | 'jpg' | 'gif' | 'mp4' | 'mp3'
    server: number
    size: number
    url: string
    usage: 'unknown' | 'user' | 'eatery' | 'dish' | 'loose'
    width: number
}

export type RecordModel = {
    flag: number
    gene: GeneModel
    ext: string
    detail: GeneModel | null
    server: number
    checksum: string
    width: number
    height: number
    size: number
    duration: number
}

export type RecordAdmin = RecordModel & {
    ext: string
    url: string
}

export type RecordInfo = Omit<RecordAdmin, 'flag' | 'gene' | 'server'>

export type SessionInfoModel = {
    client: number
    os: number
    browser: number
    device: number
    client_version: number
    os_version: number
    browser_version: number
}
export const SESSION_INFO_DEFAULT: SessionInfoModel = {
    client: 0,
    os: 0,
    browser: 0,
    device: 0,
    client_version: 0,
    os_version: 0,
    browser_version: 0,
}

export type SessionModel = {
    ip: string
    info: SessionInfoModel
    timestamp: number
    token: string
}
export const SESSION_DEFAULT: SessionModel = {
    ip: '',
    info: SESSION_INFO_DEFAULT,
    timestamp: 0,
    token: '',
}

export type UserModel = {
    agent: AgentModel

    cc: number

    created_at: number

    gene: string

    is_alive: boolean
    is_banned: boolean
    is_edited: boolean
    is_private: boolean

    name: string
    phone: string
    photo: string
    review: PondInfo
    reviews: number[]

    session_index: number
    sessions: SessionModel[]
}
export const USER_INFO_DEFAULT: UserModel = {
    gene: null,
    agent: null,
    review: null,
    photo: null,
    reviews: [0, 0, 0],
    phone: '',
    cc: 0,
    is_alive: false,
    is_edited: false,
    is_private: false,
    is_banned: false,
    created_at: 0,
    name: '',
    sessions: [SESSION_DEFAULT, SESSION_DEFAULT, SESSION_DEFAULT],
    session_index: 0,
}

export const CATEGORIES = [
    'unknown',
    'cafe',
    'restaurant',
    'fastfood',
    'seafood',
    'ice_cream',
    'dizi',
    'ash',
    'kababi',
    'kalle_pache',
    'liver',
] as const

export type EateryCategory = (typeof CATEGORIES)[number]

export type EateryCategoryDisplay = {
    [k in EateryCategory]: {
        name: string
        image: string
        icon: () => JSX.Element
        mapIcon: string
    }
}

export const EATERY_CATEGORY_DISPLAY: EateryCategoryDisplay = {
    unknown: { name: 'نامعلوم', image: '', icon: RestaurantRef, mapIcon: '' },
    cafe: {
        name: 'کافه',
        image: '/public/imgs/filters/coffee.webp',
        icon: CoffeeRef,
        mapIcon: '/public/svgs/coffee.svg',
    },
    restaurant: {
        name: 'رستوران',
        image: '/public/imgs/filters/restaurant.webp',
        icon: RestaurantRef,
        mapIcon: '/public/svgs/restaurant.svg',
    },
    fastfood: {
        name: 'فست فود',
        image: '/public/imgs/filters/pizza.webp',
        icon: PizzaRef,
        mapIcon: '/public/svgs/fastfood.svg',
    },
    seafood: {
        name: 'دریایی',
        image: '/public/imgs/filters/sea.webp',
        icon: ChopstickRef,
        mapIcon: '/public/svgs/sea.svg',
    },
    ice_cream: {
        name: 'بستنی',
        image: '/public/imgs/filters/icecream.webp',
        icon: IcecreamRef,
        mapIcon: '/public/svgs/icecream.svg',
    },
    dizi: {
        name: 'دیزی',
        image: '/public/imgs/filters/dizi.webp',
        icon: QuestionIcon,
        mapIcon: '/public/svgs/dizi.svg',
    },
    ash: {
        name: 'آش',
        image: '/public/imgs/filters/aush.webp',
        icon: QuestionIcon,
        mapIcon: '/public/svgs/aush.svg',
    },
    kababi: {
        name: 'کبابی',
        image: '/public/imgs/filters/kebab.webp',
        icon: QuestionIcon,
        mapIcon: '/public/svgs/kebab.svg',
    },
    kalle_pache: {
        name: 'کله پاچه',
        image: '/public/imgs/filters/khash.webp',
        icon: QuestionIcon,
        mapIcon: '/public/svgs/khash.svg',
    },
    liver: {
        name: 'جگر',
        image: '/public/imgs/filters/liver.webp',
        icon: QuestionIcon,
        mapIcon: '/public/svgs/liver.svg',
    },
}

export const THEMES = [
    'unknown',
    'luxury',
    'romantic',
    'friendly',
    'family',
    'modern',
    'business',
    'sports',
    'mafia',
    'musical',
    'traditional',
    'nature',
] as const

export type EateryTheme = (typeof THEMES)[number]
type EateryThemeDisplay = {
    [k in EateryTheme]: { name: string; image: string; icon: () => JSX.Element }
}

export const EATERY_THEME_DISPLAY: EateryThemeDisplay = {
    unknown: { name: 'نامعلوم', image: '', icon: QuestionIcon },
    luxury: {
        name: 'لاکچری',
        image: '/public/imgs/themes/luxury.webp',
        icon: () => null,
    },
    romantic: {
        name: 'رمانتیک',
        image: '/public/imgs/themes/romantic.webp',
        icon: () => null,
    },
    friendly: {
        name: 'دوستانه',
        image: '/public/imgs/themes/friends.webp',
        icon: () => null,
    },
    family: {
        name: 'خانوادگی',
        image: '/public/imgs/themes/family.webp',
        icon: () => null,
    },
    modern: {
        name: 'مدرن',
        image: '/public/imgs/themes/modern.webp',
        icon: () => null,
    },
    business: {
        name: 'بیزینسی',
        image: '/public/imgs/themes/business.webp',
        icon: () => null,
    },
    sports: {
        name: 'ورزشی',
        image: '/public/imgs/themes/sport.webp',
        icon: () => null,
    },
    mafia: {
        name: 'مافیا',
        image: '/public/imgs/themes/mafia.webp',
        icon: () => null,
    },
    musical: {
        name: 'موزیکال',
        image: '/public/imgs/themes/musical.webp',
        icon: () => null,
    },
    traditional: {
        name: 'سنتی',
        image: '/public/imgs/themes/culture.webp',
        icon: () => null,
    },
    nature: {
        name: 'طبیعت',
        image: '/public/imgs/themes/nature.webp',
        icon: () => null,
    },
}

export type Duration = {
    open: number
    close: number
}
export const DURATION_DEFAULT: Duration = {
    open: 0,
    close: 0,
}

export const PersianDaysOfWeek: string[] = [
    'شنبه',
    'یک‌شنبه',
    'دوشنبه',
    'سه‌شنبه',
    'چهارشنبه',
    'پنج‌شنبه',
    'جمعه',
]

export type Day = [Duration, Duration, Duration, Duration]
export const DAY_DEFAULT: Day = [
    DURATION_DEFAULT,
    DURATION_DEFAULT,
    DURATION_DEFAULT,
    DURATION_DEFAULT,
]
export type OpeningHours = [Day, Day, Day, Day, Day, Day, Day]
export const OPENING_HOURS_DEFAULT: OpeningHours = [
    DAY_DEFAULT,
    DAY_DEFAULT,
    DAY_DEFAULT,
    DAY_DEFAULT,
    DAY_DEFAULT,
    DAY_DEFAULT,
    DAY_DEFAULT,
]

export type EateryModel = {
    gene: GeneModel
    latitude: number
    longitude: number
    menu: OriginInfo | null
    review: OriginInfo | null
    detail: GeneModel
    extra: GeneModel
    photos: [
        GeneModel,
        GeneModel,
        GeneModel,
        GeneModel,
        GeneModel,
        GeneModel,
        GeneModel,
    ]
    stars: [number, number, number, number, number]
    theme: EateryTheme
    is_alive: boolean
    is_close: boolean
    is_edited: boolean
    is_for_guest: boolean
    is_private: boolean
    is_takeaway: boolean
    cc: number
    tables: number
    category: EateryCategory
    zoom: number
    phone: string
    opening_hours: OpeningHours
    name: string
}

export const EATERY_INFO_DEFAULT: EateryInfo = {
    gene: null,
    latitude: 0,
    longitude: 0,
    menu: null,
    review: null,
    detail: null,
    extra: null,
    photos: [null, null, null, null, null, null, null],
    stars: [0, 0, 0, 0, 0],
    theme: 'unknown',
    is_for_guest: false,
    is_takeaway: false,
    is_close: false,
    is_alive: false,
    is_edited: false,
    is_private: false,
    cc: 0,
    tables: 0,
    category: 'unknown',
    zoom: 0,
    phone: '',
    opening_hours: OPENING_HOURS_DEFAULT,
    name: '',
}

export type EaterySearch = {
    gene: GeneModel
    category: number
    is_close: boolean
    is_private: boolean
    latitude: number
    longitude: number
    name: string
    review_count: number
    star_sum: number
    stars: number
    theme: number
    thumbnail: string
    opening_hours: OpeningHours
    phone: string
    cc: number
    tables: number
}

export type EaterySort = 'review_count' | 'star_sum' | 'stars'

export type EateryFilter =
    | { kind: 'is_close'; value: boolean }
    | { kind: 'is_private'; value: boolean }
    | { kind: 'is_for_guest'; value: boolean }
    | { kind: 'category'; value: FilterNumber }
    | { kind: 'theme'; value: FilterNumber }
    | { kind: 'stars'; value: FilterNumber }
    | { kind: 'or' }
    | { kind: 'and' }
    | { kind: 'group'; value: EateryFilter[] }

type EaterySortDisplayType = {
    [k in EaterySort]: { name: string }
}

export const EaterySortDisplay: EaterySortDisplayType = {
    review_count: { name: 'تعداد نظر' },
    star_sum: { name: 'جمع ستاره' },
    stars: { name: 'ستاره' },
}

export type SortOrder = 'desc' | 'asc'

export type FilterOperations = 'gt' | 'lt' | 'eq' | 'ne'

export type FilterNumber = { opt: FilterOperations; val: number }

export const OperationInPersian = {
    gt: 'بیشتر از',
    lt: 'کمتر از',
    eq: 'برابر',
    ne: 'مخالف با',
    in: 'درون',
    not_in: 'نبودن درون',
}

type SortOrderDisplayType = {
    [k in SortOrder]: { name: string }
}
export const SortOrderDisplay: SortOrderDisplayType = {
    desc: { name: 'بالا به پایین' },
    asc: { name: 'پایین به بالا' },
}

export type EateryInfo = Omit<EateryModel, 'flag' | 'reivew' | 'private'> & {
    photos: (RecordInfo | null)[]
}

export type DishKind = 'unknown' | 'food' | 'drink' | 'dessert'
export type DishInfo = {
    gene: GeneModel
    pond: GeneModel
    eatery: GeneModel
    price: number
    photos: [GeneModel, GeneModel, GeneModel, GeneModel]
    kind: DishKind
    is_alive: boolean
    is_edited: boolean
    is_private: boolean
    is_available: boolean
    name: string
    note: string
}

export type DishList = {
    next: GeneModel
    past: GeneModel
    list: DishInfo[]
}

export type BlockHeader = {
    index: GeneModel
    gene: GeneModel
    next: GeneModel | null
    past: GeneModel | null
    live: number
    flag: number
    alive: boolean
    edited: boolean
    private: boolean
}

export type PondIndex = {
    flag: number
    gene: GeneModel
    owner: GeneModel
    block_count: number
    item_count: number
    first: GeneModel
    last: GeneModel
    alive: boolean
    edited: boolean
    private: boolean
}

export type OriginInfo = {
    gene: GeneModel
    owner: GeneModel
    ponds: number
    items: number
    first: GeneModel
    last: GeneModel
    is_alive: boolean
    is_edited: boolean
    is_private: boolean
}

export type ReviewList = {
    list: ReviewModel[]
    next: string
    past: string
}

export type ReviewState =
    | 'default'
    | 'bot_censored'
    | 'bot_verified'
    | 'staff_censored'
    | 'staff_verified'
    | 'admin_censored'
    | 'admin_verified'
    | 'user_deleted'

export type ReviewStatePersianType = {
    [k in ReviewState]: {
        label: string
        icon: () => JSX.Element | null
    }
}

export const ReviewStatePersian: ReviewStatePersianType = {
    admin_censored: { label: 'رد توسط ادمین', icon: CloseIcon },
    admin_verified: { label: 'تایید توسط ادمین', icon: Check2Icon },
    bot_censored: { label: 'رد توسط سیستم', icon: CloseIcon },
    bot_verified: { label: 'تایید توسط سیستم', icon: Check2Icon },
    default: { label: 'منتظر تصمیم', icon: EyeIcon },
    staff_censored: { label: 'تایید توسط غذاخوری', icon: Check2Icon },
    staff_verified: { label: 'رد توسط غذاخوری', icon: CloseIcon },
    user_deleted: { label: 'حذف شده توسط کاربر', icon: TrashIcon },
}

export type ReviewModel = {
    cousin: string
    detail: string
    eatery: string
    gene: string
    is_alive: boolean
    is_edited: boolean
    is_private: boolean
    pond: string
    star: number
    state: ReviewState
    summary: string
    timestamp: number
    user: string
}

export type SavedEateriesModel = string[]
