import { SaveIcon } from '!/icons'
import {
    ArrowLeftIcon,
    CommentIcon,
    LogoutIcon,
    MoonIcon,
    SunIcon,
} from '!/icons/account'
import { PersonFillSvg } from '!/icons/navbar'
import { MapChilrenType } from '!/index'
import { logout_user, setTheme, theme } from '!/store'
import { Component, createSignal, onMount, Setter, Show } from 'solid-js'
import Info from './info'

import Reviews from './reviews'
import Saved from './saved'
import './style/account.scss'

export type sectionsType = 'info' | 'saved' | 'comments' | ''

export interface AccountChildProps {
    setActiveSection: Setter<sectionsType>
}
export const [activeSection, setActiveSection] = createSignal<sectionsType>('')

const Account: Component<MapChilrenType> = P => {
    // const setHeight = () => {
    //     let nav = document.querySelector<HTMLElement>('nav.nav-container')
    //     let home = document.querySelector<HTMLElement>('main.account')

    //     home.style.height = `calc(100% - ${
    //         nav.getBoundingClientRect().height
    //     }px)`
    // }

    // onMount(() => {
    //     setHeight()

    //     window.onresize = () => {
    //         setHeight()
    //     }
    // })

    return (
        <main class='account' classList={{ active: P.active }}>
            <div
                class='active-section-container'
                classList={{ active: activeSection() !== '' }}
            >
                <div class='section-wrapper title'>
                    <Show when={activeSection() === 'info'}>
                        <Info setActiveSection={setActiveSection} />
                    </Show>
                    <Show when={activeSection() === 'saved'}>
                        <Saved setActiveSection={setActiveSection} />
                    </Show>
                    <Show when={activeSection() === 'comments'}>
                        <Reviews setActiveSection={setActiveSection} />
                    </Show>
                </div>
            </div>

            <ThemeChanger />

            <div class='rows-container'>
                <button
                    class='account-row'
                    onclick={() => setActiveSection('info')}
                >
                    <div class='icon holder-icon'>
                        <PersonFillSvg />
                    </div>
                    <h3 class='title'>اطلاعات شخصی</h3>
                    <div class='go-icon icon'>
                        <ArrowLeftIcon />
                    </div>
                </button>
                <button
                    class='account-row'
                    onclick={() => setActiveSection('saved')}
                >
                    <div class='icon holder-icon'>
                        <SaveIcon fill />
                    </div>
                    <h3 class='title'>مورد علاقه هام</h3>
                    <div class='go-icon icon'>
                        <ArrowLeftIcon />
                    </div>
                </button>
                <button
                    class='account-row'
                    onclick={() => setActiveSection('comments')}
                >
                    <div class='icon holder-icon'>
                        <CommentIcon />
                    </div>
                    <h3 class='title'>نظرات من</h3>
                    <div class='go-icon icon'>
                        <ArrowLeftIcon />
                    </div>
                </button>
                <button
                    class='account-exit'
                    onclick={() => {
                        logout_user().then(() => {
                            location.reload()
                        })
                    }}
                >
                    <div class='icon holder-icon'>
                        <LogoutIcon />
                    </div>
                    <h3 class='title'>خروج از حساب</h3>
                    <div class='go-icon icon'>
                        <ArrowLeftIcon />
                    </div>
                </button>
            </div>
        </main>
    )
}

const ThemeChanger: Component = P => {
    let themeswitch: HTMLInputElement

    onMount(() => {
        themeswitch = document.querySelector('input#theme-btn')

        themeswitch.addEventListener('click', switchTheme)
    })

    function switchTheme(e) {
        if (e.target.checked) {
            setTheme('dark')
        } else {
            setTheme('light')
        }
    }

    return (
        <div class='theme-container' classList={{ dark: theme() === 'dark' }}>
            <div class='theme-background'>
                <div class='theme sun'>
                    <Sun />
                    <Clouds />
                </div>
                <div class='theme moon'>
                    <Moon />
                    <Stars />
                </div>
            </div>

            <label class='theme-switch' for='theme-btn'>
                <input
                    type='checkbox'
                    id='theme-btn'
                    checked={theme() === 'dark'}
                />
                <div class='switch-circle'>
                    <div class='icon sun'>
                        <SunIcon />
                    </div>
                    <div class='icon moon'>
                        <MoonIcon />
                    </div>
                </div>
                <div class='stars-container'>
                    <span class='star star-1'></span>
                    <span class='star star-2'></span>
                    <span class='star star-3'></span>
                    <span class='star star-4'></span>
                    <span class='star star-5'></span>
                    <span class='star star-6'></span>
                </div>
            </label>
        </div>
    )
}

const Moon: Component = () => {
    return (
        <div class='moon-container'>
            <div class='moon-circle'></div>
        </div>
    )
}

const Stars: Component = () => {
    return (
        <div class='stars-container'>
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
        </div>
    )
}

const Sun: Component = () => {
    return (
        <div class='sun-wrapper'>
            <div class='sun-circle'></div>
            <div class='sun-line'></div>
            <div class='sun-line'></div>
            <div class='sun-line'></div>
            <div class='sun-line'></div>
            <div class='sun-line'></div>
            <div class='sun-line'></div>
            <div class='sun-line'></div>
            <div class='sun-line'></div>
            <div class='sun-line'></div>
            <div class='sun-line'></div>
            <div class='sun-line'></div>
        </div>
    )
}

const Clouds: Component = () => {
    return (
        <div class='clouds-wrapper'>
            <div class='cloud normal cloud-1'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class='cloud large cloud-2'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class='cloud small cloud-3'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class='cloud tiny cloud-4'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class='cloud large cloud-5'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class='cloud normal cloud-6'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Account
