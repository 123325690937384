import { GeneModel, OpeningHours, SessionInfoModel } from '!/models'
import { UAParser } from 'ua-parser-js'

const parser = new UAParser(navigator.userAgent)

export function record_url(gene: GeneModel): string {
    if (gene.startsWith('https')) return gene

    return `/record/${gene}`
}

export const cubicBezier = (
    t: number,
    p1: number,
    p2: number,
    p3: number,
    p4: number
) => {
    return (
        3 * p1 * (1 - t) * (1 - t) * t +
        3 * p2 * (1 - t) * t * t +
        p4 * t * t * t
    )
}

const OS = ['unknown', 'linux', 'windows', 'mac', 'ios', 'android']
const BROWSERS = ['unknown', 'chrome', 'firefox', 'safari', 'opera', 'edge']
const DEVICES = ['unknown', 'desktop', 'mobile', 'embeded']

export function convert_version(version: string | number) {
    if (!version) return 0

    if (typeof version == 'string') {
        version = parseInt(version.split('.')[0]) || 1
    }

    return Math.max(Math.min(version, 1 << 15), 1)
}

export function get_session_info(): SessionInfoModel {
    let { os, device, browser } = parser.getResult()

    let osn = (os.name || '').toLowerCase()
    let brn = (browser.name || '').toLowerCase()
    let dvn = (device.type || '').toLowerCase()

    return {
        client: 2,
        os: Math.max(OS.indexOf(osn), 0),
        browser: Math.max(BROWSERS.indexOf(brn), 0),
        device: Math.max(DEVICES.indexOf(dvn), 0),
        os_version: convert_version(os.version),
        client_version: 1,
        browser_version: convert_version(browser.version),
    }
}

export const formatOhTime = (time: number): string | null => {
    if (time === 0) return null
    const totalMinutes = (time - 1) * 15
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
        2,
        '0'
    )}`
}

export function isMobile() {
    const userAgent = navigator.userAgent || navigator.vendor
    const isTouchDevice =
        'ontouchstart' in window || navigator.maxTouchPoints > 0
    const isSmallScreen = window.matchMedia('(max-width: 768px)').matches

    return (
        /android|iphone|ipad|ipod|opera mini|iemobile|mobile/i.test(
            userAgent
        ) ||
        isTouchDevice ||
        isSmallScreen
    )
}

export function isOpenNow(openingHours: OpeningHours): boolean {
    const now = new Date()
    const currentDay = now.getDay()
    const currentHour = now.getHours()
    const currentMinutes = now.getMinutes()

    // Calculate the current 15-minute slot
    const currentSlot = Math.floor(currentHour * 4 + currentMinutes / 15)

    // Get today's opening intervals
    const todayIntervals = openingHours[currentDay]

    // Check if current slot falls in any interval
    for (const interval of todayIntervals) {
        if (interval.open <= currentSlot && currentSlot < interval.close) {
            return true
        }
    }

    return false
}

export function getOpenUntil(openingHours: OpeningHours): string {
    const now = new Date()
    const currentDay = now.getDay()
    const currentHour = now.getHours()
    const currentMinutes = now.getMinutes()

    // Get today's opening hours
    const todaysHours = openingHours[currentDay]

    const currentSlot = Math.floor(currentHour * 4 + currentMinutes / 15)

    // Find the current active period
    const currentPeriod = todaysHours.find(
        period => period.open <= currentSlot && period.close > currentSlot
    )

    if (currentPeriod) {
        return formatOhTime(currentPeriod.close)
    }

    return '-- : --'
}
